import React from "react";

type Props = {};

const User = ({}: Props): JSX.Element => {
  return (
    <div>
      <h1>User</h1>
    </div>
  );
};

export default User;
